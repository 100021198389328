
import { computed, defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { parseDate } from "@/core/helpers/globalJaya";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "PrescribersManagement",
  components: { CardTitleJaya, Datatable },
  setup() {
    const store = useStore();
    const reloadTable = ref<number>(1);

    onMounted(() => {
      Promise.all([
        store.dispatch(Actions.FETCH_GDPR_LAST_DOCS, { doc_type: 5 }),
        store.dispatch(Actions.FETCH_PRESCRIBER_FOUNDERS),
        store.dispatch(Actions.FETCH_PRESCRIBER_PROJECT_FOUNDERS),
      ]).then(() => (reloadTable.value += 1));
    });

    const prescribers = computed(() => {
      return store.getters.getPrescribers;
    });

    const prescriberProjects = computed(() => {
      return store.getters.getPrescriberProjects;
    });

    const prescriberProjectStates = computed(() => {
      return store.getters.getPrescriberProjectStates;
    });

    const prescriberCharter = computed(() => {
      const prescriberCharterChoice = store.getters.getGdprDocTypes.find(
        (e) => e.name === "prescriber-charter"
      ).choice;
      return store.getters.getGdprLastDocs.find(
        (e) => e.doc_type === prescriberCharterChoice
      );
    });

    const prescriberListHeader = ref([
      {
        name: "Prescripteur",
        key: "name",
        sortable: true,
      },
      {
        name: "Administrateur",
        key: "admin",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false,
      },
    ]);

    const prescriberProjectListHeader = ref([
      {
        name: "Projet",
        key: "name",
        sortable: true,
      },
      {
        name: "Prescripteur",
        key: "prescriber",
        sortable: true,
      },
      {
        name: "Etat",
        key: "state",
        sortable: true,
      },
      {
        name: "Utilisateurs",
        key: "exploitations",
        sortable: true,
      },
      {
        name: "Diagnostics",
        key: "diagnostics",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
        sortable: false,
      },
    ]);

    const openValidationSwal = (prescriberProject) => {
      Swal.fire({
        title: "Vous êtes sur le point de valider le projet d'un prescripteur",
        html:
          "Souhaitez-vous valider le projet <strong>" +
          prescriberProject.name +
          "</strong> porté par le prescripteur <em>" +
          getPrescriberNameFromProject(prescriberProject) +
          "</em> ?" +
          "<br><br> Une fois validé, le prescripteur pourra envoyer aux agriculteurs des invitations à rejoindre le projet.",
        icon: "warning",
        confirmButtonText: "Valider le projet",
        showCancelButton: true,
        cancelButtonText: "Retour",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          prescriberProject.state =
            store.getters.getPrescriberProjectStates.find(
              (e) => e.name === "Validated"
            ).state;
          store
            .dispatch(
              Actions.UPDATE_PRESCRIBER_PROJECT_FOUNDER,
              prescriberProject
            )
            .then(() => (reloadTable.value += 1));
        }
      });
    };

    const fireDeletePrescriberSwal = (prescriber) => {
      Swal.fire({
        title: "Vous êtes sur le point de supprimer un prescripteur",
        html:
          "Souhaitez-vous vraiment supprimer le prescripteur <strong>" +
          prescriber.name +
          "</strong> ? Cette opération est irréversible. Cela supprimera toutes les informations du prescripteurs :" +
          " son profil, ses projets, ainsi que le lien de rattachement entre les projets et les utilisateurs <em>" +
          "</em> ?",
        icon: "error",
        confirmButtonText: "Supprimer le prescripteur",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light-warning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_PRESCRIBER_FOUNDERS, prescriber)
            .then(() => {
              reloadTable.value += 1;
            });
        }
      });
    };

    const firePrescriberProjectDeleteSwal = (prescriberProject) => {
      Swal.fire({
        title: "Vous êtes sur le point de supprimer un projet",
        html:
          "Souhaitez-vous vraiment supprimer le projet <strong>" +
          prescriberProject.name +
          "</strong> ? Cette opération est irréversible. Cela supprimera toutes les informations du projet " +
          "et le rattachement entre le projet et les utilisateurs <em>" +
          "</em> ?",
        icon: "error",
        confirmButtonText: "Supprimer le projet",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light-warning",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch(
              Actions.DELETE_PRESCRIBER_PROJECT_FOUNDERS,
              prescriberProject
            )
            .then(() => {
              reloadTable.value += 1;
            });
        }
      });
    };

    const getPrescriberNameFromProject = (prescriberProject) => {
      const prescriber = prescribers.value.find(
        (p) => p.id === prescriberProject.prescriber
      );
      return prescriber ? prescriber.name : "";
    };

    return {
      prescribers,
      prescriberProjects,
      prescriberProjectStates,
      prescriberCharter,
      parseDate: parseDate,
      prescriberListHeader,
      prescriberProjectListHeader,
      openValidationSwal,
      fireDeletePrescriberSwal,
      firePrescriberProjectDeleteSwal,
      getPrescriberNameFromProject,
      reloadTable,
    };
  },
});


import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { StepperComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "FactorAdviceBackOfficePreview",
  components: {
    CardTitleJaya,
  },
  setup() {
    const store = useStore();
    const stats = ref<Record<string, any>>({});
    let professionalOnly = ref<boolean>(false);

    watch(
      () => professionalOnly.value,
      (value) => {
        store
          .dispatch(Actions.GET_GENERAL_STATS, { professional_only: value })
          .then((response) => {
            stats.value = response[0];
          });
      }
    );

    const chartOptionsStatic = {
      chart: {
        type: "pie",
        height: 380,
      },
      legend: {
        position: "bottom",
      },
    };

    const createChartOptions = (data: any, titleText: string) => {
      const series = data ? Object.values(data) : [];
      const labels = data ? Object.keys(data) : [];

      return {
        ...chartOptionsStatic,
        series,
        labels,
        title: {
          text: titleText,
          align: "center",
        },
      };
    };

    const chartOptionsUsers = computed(() =>
      createChartOptions(
        stats.value.user?.professional_farmer,
        "Agriculteurs à titre principal ou secondaire"
      )
    );

    const chartOptionsExploitationsLegalStatus = computed(() =>
      createChartOptions(
        stats.value.exploitation?.legal_status,
        "Statut Juridique"
      )
    );

    const chartOptionsExploitationsRegions = computed(() =>
      createChartOptions(stats.value.exploitation?.region, "Région")
    );

    const chartOptionsExploitationsVersionsOtex = computed(() =>
      createChartOptions(stats.value.exploitation_version?.otex, "OTEX")
    );

    const chartOptionsExploitationsVersionsSIQO = computed(() =>
      createChartOptions(stats.value.exploitation_version?.siqo, "SIQO")
    );

    const chartOptionsExploitationsVersionsBiodivProgress = computed(() =>
      createChartOptions(
        stats.value.exploitation_version?.biodiversity_progress,
        "Avancement biodiversité"
      )
    );

    const chartOptionsDiagnosticFinished = computed(() =>
      createChartOptions(stats.value.diagnostic?.finished, "Terminés ?")
    );

    const chartOptionsDiagnosticIsReal = computed(() =>
      createChartOptions(stats.value.diagnostic?.is_real, "Réels ?")
    );

    onMounted(() => {
      store
        .dispatch(Actions.GET_GENERAL_STATS, { professional_only: false })
        .then((response) => {
          stats.value = response[0];
        });
    });

    return {
      chartOptionsUsers,
      chartOptionsExploitationsLegalStatus,
      chartOptionsExploitationsRegions,
      chartOptionsExploitationsVersionsOtex,
      chartOptionsExploitationsVersionsSIQO,
      chartOptionsExploitationsVersionsBiodivProgress,
      chartOptionsDiagnosticIsReal,
      chartOptionsDiagnosticFinished,
      professionalOnly,
      stats,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-0 w-100 align-items-stretch" }
const _hoisted_2 = { class: "col col-12" }
const _hoisted_3 = { class: "card h-100" }
const _hoisted_4 = { class: "card-header card-header-jaya small" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "card-body border-top" }
const _hoisted_7 = { class: "col col-12 mt-5" }
const _hoisted_8 = { class: "col col-12 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_title_jaya = _resolveComponent("card-title-jaya")!
  const _component_ResultDataBackOffice = _resolveComponent("ResultDataBackOffice")!
  const _component_DataBackOfficePreview = _resolveComponent("DataBackOfficePreview")!
  const _component_PrescribersManagement = _resolveComponent("PrescribersManagement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_card_title_jaya, {
            "duotune-icon": "graphs/gra010",
            title: "Résultats des diagnostics",
            small: ""
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSeriesPage())),
              class: "btn btn-primary btn-sm py-1 px-3"
            }, _toDisplayString(_ctx.seriesPage ? "-" : "+"), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_ResultDataBackOffice, { seriesPage: _ctx.seriesPage }, null, 8, ["seriesPage"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_DataBackOfficePreview)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_PrescribersManagement)
    ])
  ]))
}
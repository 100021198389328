
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ResultDataBackOffice from "@/components/backoffice/ResultDataBackOffice.vue";
import DataBackOfficePreview from "@/components/backoffice/DataBackOfficePreview.vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import PrescribersManagement from "@/components/backoffice/PrescribersManagement.vue";

export default defineComponent({
  name: "homepage",
  components: {
    PrescribersManagement,
    CardTitleJaya,
    ResultDataBackOffice,
    DataBackOfficePreview,
  },
  setup() {
    let seriesPage = ref<boolean>(false);

    const toggleSeriesPage = () => {
      seriesPage.value = !seriesPage.value;
    };

    onMounted(() => {
      setCurrentPageTitle("Back Office");
    });

    return {
      toggleSeriesPage,
      seriesPage,
    };
  },
});


import { computed, defineComponent, onMounted, ref } from "vue";
import FactorLegend from "@/components/diagnostic/FactorLegend.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";
import { notify } from "@/core/helpers/globalJaya";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "ResultDataBackOffice",
  components: {
    FactorLegend,
    Multiselect,
  },
  props: {
    seriesPage: Boolean,
  },
  setup() {
    interface SeriesObject {
      id: number;
      name: string;
      concernedDiagnostics: number;
      data: number[];
      realityOptionChoice: number;
      OTEXChoice: never[];
      SIQOChoice: never[];
      locationType: string;
      locations: Location[];
      creationStartDate: Date | null;
      creationEndDate: Date | null;
      evaluationStartDate: Date | null;
      evaluationEndDate: Date | null;
    }

    interface MultiselectOption {
      value: number;
      label: string;
    }

    interface LocationType {
      value: number;
      label: string;
      name: string;
    }

    interface Location {
      id: number;
      name: string;
    }

    interface LocationResponse {
      count: number;
      next: string | null;
      previous: string | null;
      results: Array<Location>;
    }

    const store = useStore();

    let chartType = ref<string>("bar");
    let series = ref<SeriesObject[]>([
      {
        id: 0,
        name: "Série 1",
        concernedDiagnostics: 0,
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        realityOptionChoice: 2,
        OTEXChoice: [],
        SIQOChoice: [],
        locationType: "",
        locations: [],
        creationStartDate: null,
        creationEndDate: null,
        evaluationStartDate: null,
        evaluationEndDate: null,
      },
    ]);
    let realityOptions = ref<Array<unknown>>([
      {
        id: 0,
        name: "Réels",
      },
      {
        id: 1,
        name: "Simulés",
      },
      {
        id: 2,
        name: "Tous",
      },
    ]);
    let locationTypeOptions = ref<Array<LocationType | null>>([
      {
        value: 0,
        label: "Régions",
        name: "regions",
      },
      {
        value: 1,
        label: "Départements",
        name: "departments",
      },
      {
        value: 2,
        label: "EPCI",
        name: "epcis",
      },
      {
        value: 3,
        label: "Communes",
        name: "cities",
      },
      {
        value: 4,
        label: "Petites régions agricoles",
        name: "pras",
      },
    ]);
    let locationTypeChoice = ref<LocationType | null>(null);
    let locationOptions = ref<LocationResponse | null>(null);
    let editingLocationType = ref<string>("");
    let editingLocations = ref<Array<Location>>([]);
    let searchText = ref<string>("");
    let searchLimit = ref<number>(20);
    let searchOffset = ref<number>(0);
    let waitScroll = ref<boolean>(false);
    let displayChart = ref<boolean>(true);

    const getOTEX = computed(() => store.getters.getOTEX);
    const OTEXMultiselect = computed(() => {
      let optionsArray = [] as Array<MultiselectOption>;
      for (const otex of getOTEX.value) {
        optionsArray.push({
          value: otex.id,
          label: otex.name,
        });
      }
      return {
        options: optionsArray,
        mode: "tags",
        value: [],
        searchable: true,
      };
    });
    const getSIQO = computed(() => store.getters.getSIQO);
    const SIQOMultiselect = computed(() => {
      let optionsArray = [] as Array<MultiselectOption>;
      for (const siqo of getSIQO.value) {
        optionsArray.push({
          value: siqo.id,
          label: siqo.name,
        });
      }
      return {
        options: optionsArray,
        mode: "tags",
        value: [],
        searchable: true,
      };
    });
    const factors = computed(() => store.getters.getFactors);
    const chartOptionsBar = computed(() => {
      return {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return Math.round((value - 1) * 10) / 10;
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true,
          lineCap: "butt",
          width: 0.5,
          colors: ["#fff"],
          opacity: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: (label) => {
              let modifiedLabel = factors.value.find(
                (factor) => factor.number === label
              )?.title;
              return label + " : " + modifiedLabel;
            },
          },
          y: {
            formatter: (value) => {
              return (
                "Niveau " + (Math.round((value - 1) * 100) / 100).toString()
              );
            },
          },
          followCursor: true,
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
          inverseOrder: true,
        },
        yaxis: {
          position: "top",
          tickAmount: 6,
          min: 0,
          max: 6,
          labels: {
            show: false,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
        },
        fill: {
          opacity: 1,
        },
      };
    });
    const chartOptionsRadar = computed(() => {
      return {
        chart: {
          type: "radar",
        },
        plotOptions: {
          radar: {
            distributed: true,
          },
        },
        colors: [
          getCSSVariableValue("--bs-primary"),
          getCSSVariableValue("--bs-secondary"),
          getCSSVariableValue("--bs-dark"),
          getCSSVariableValue("--bs-warning"),
          getCSSVariableValue("--bs-danger"),
        ],
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            return Math.round((value - 1) * 10) / 10;
          },
          style: {
            fontSize: "10px",
          },
        },
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        xaxis: {
          categories: factors.value.map((factor) => factor.number),
        },
        yaxis: {
          show: false,
        },
        legend: {
          position: "bottom",
        },
      };
    });

    const setType = (type) => {
      displayChart.value = false;
      chartType.value = type;
      setTimeout(() => {
        displayChart.value = true;
      }, 10);
    };
    const addSeries = () => {
      const newId = Number(
        Math.max(...series.value.map((serie) => serie.id)) + 1
      ) as number;
      series.value.push({
        id: newId,
        name: "Série " + (Number(series.value.length) + 1),
        concernedDiagnostics: 0,
        data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        realityOptionChoice: 2,
        OTEXChoice: [],
        SIQOChoice: [],
        locationType: "",
        locations: [],
        creationStartDate: null,
        creationEndDate: null,
        evaluationStartDate: null,
        evaluationEndDate: null,
      });
      getNewFilteredSeries(newId);
    };
    const removeSerie = (index) => {
      series.value.splice(index, 1);
    };
    const getNewFilteredSeries = (id) => {
      let serie = series.value.find((item) => item.id === id) as SeriesObject;
      serie.locationType = editingLocationType.value.slice();
      serie.locations = editingLocations.value.slice();
      if (serie) {
        let payload = ref<any>({});
        if (serie.creationStartDate) {
          payload.value.date_created_min = serie.creationStartDate;
        }
        if (serie.creationEndDate) {
          payload.value.date_created_max = serie.creationEndDate;
        }
        if (serie.evaluationStartDate) {
          payload.value.date_evaluated_min = serie.evaluationStartDate;
        }
        if (serie.evaluationEndDate) {
          payload.value.date_evaluated_max = serie.evaluationEndDate;
        }
        switch (serie.realityOptionChoice) {
          case 0:
            payload.value.is_real = true;
            break;
          case 1:
            payload.value.is_real = false;
            break;
        }
        if (serie.OTEXChoice.length > 0) {
          payload.value.otex = serie.OTEXChoice;
        }
        if (serie.SIQOChoice.length > 0) {
          payload.value.siqo = serie.SIQOChoice;
        }
        if (serie.locations.length > 0) {
          payload.value[serie.locationType] = serie.locations.map(
            (location) => location.id
          );
        }
        store
          .dispatch(Actions.GET_DIAGNOSTIC_STATS_FOUNDERS, payload.value)
          .then((response) => {
            let responseData = response[0]["stats"];
            serie.concernedDiagnostics = responseData.count["F1-1"];
            if (serie.concernedDiagnostics > 0) {
              let resultArray = [] as Array<number>;
              for (const key of Object.keys(responseData.avg)) {
                resultArray.push(1 + responseData.avg[key]);
              }
              serie.data = resultArray;
            } else {
              serie.data = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
            }
          });
      }
    };
    const getNewLocations = (serie) => {
      editingLocations.value = [];
      if (
        locationTypeChoice.value &&
        serie.locationType === locationTypeChoice.value.name
      ) {
        editingLocations.value = serie.locations.slice();
      }
      searchText.value = "";
      editingLocationType.value = "";
      let payload = ref<any>({});
      if (locationTypeChoice.value) {
        editingLocationType.value = locationTypeChoice.value.name;
        payload.value.type = locationTypeChoice.value.name;
        locationOptions.value = null;
        store
          .dispatch(Actions.GET_LOCATIONS, payload.value)
          .then((response) => {
            locationOptions.value = response;
          })
          .catch(() => {
            locationTypeChoice.value = null;
            notify({
              text: "Une erreur est survenue à la récupration des lieux",
              color: "error",
              duration: 3000,
            });
          });
      }
    };
    const scrollListDiv = (event) => {
      if (
        locationOptions.value &&
        locationOptions.value.next &&
        event.target.scrollTopMax - event.target.scrollTop < 50
      ) {
        loadNext();
      }
    };
    const loadNext = () => {
      if (!waitScroll.value && locationTypeChoice.value) {
        waitScroll.value = true;
        let payload = {
          name: searchText.value,
          limit: searchLimit.value,
          offset: searchOffset.value + searchLimit.value,
          type: locationTypeChoice.value.name,
        };
        store
          .dispatch(Actions.GET_LOCATIONS, payload)
          .then((response) => {
            if (locationOptions.value) {
              locationOptions.value.next = response.next;
              searchOffset.value = searchOffset.value + searchLimit.value;
              locationOptions.value.results =
                locationOptions.value.results.concat(response.results);
            } else {
              locationOptions.value = response;
            }
            waitScroll.value = false;
          })
          .catch(() => {
            waitScroll.value = false;
          });
      }
    };
    const search = () => {
      setTimeout(() => {
        searchOffset.value = 0;
        locationOptions.value = null;
        if (locationTypeChoice.value) {
          let payload = {
            name: searchText.value,
            limit: searchLimit.value,
            offset: searchOffset.value,
            type: locationTypeChoice.value.name,
          };
          store
            .dispatch(Actions.GET_LOCATIONS, payload)
            .then((response) => {
              locationOptions.value = response;
            })
            .catch(() => {
              locationTypeChoice.value = null;
              notify({
                text: "Une erreur est survenue à la récupration des lieux",
                color: "error",
                duration: 3000,
              });
            });
        }
      }, 300);
    };
    const addLocation = (location) => {
      if (editingLocations.value.some((item) => item.id === location.id)) {
        let index = editingLocations.value.findIndex(
          (item) => item.id === location.id
        );
        editingLocations.value.splice(index, 1);
      } else {
        let maxItems = 100;
        if (editingLocations.value.length >= maxItems) {
          notify({
            text:
              "Il est impossible de selectionner plus de " +
              maxItems +
              " lieux d'un coup",
            color: "error",
            duration: 3000,
          });
        } else {
          editingLocations.value.push({
            id: location.id,
            name: location.name,
          });
        }
      }
    };

    const startDataDownload = (anonymous) => {
      store
        .dispatch(Actions.SEND_MAIL_DB_DOWNLOAD_CSV, { anonymous: anonymous })
        .then(() => {
          Swal.fire({
            text: "Un e-mail a été envoyé, dans lequel vous trouverez un lien de téléchargement.",
            icon: "success",
            confirmButtonText: "J'ai compris",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-light-danger",
            },
          });
        });
    };

    onMounted(() => {
      getNewFilteredSeries(0);
    });

    return {
      chartType,
      series,
      realityOptions,
      displayChart,
      locationTypeOptions,
      locationTypeChoice,
      locationOptions,
      editingLocationType,
      editingLocations,
      searchText,
      getOTEX,
      OTEXMultiselect,
      getSIQO,
      SIQOMultiselect,
      chartOptionsBar,
      chartOptionsRadar,
      setType,
      getNewFilteredSeries,
      addSeries,
      removeSerie,
      getNewLocations,
      scrollListDiv,
      search,
      addLocation,
      startDataDownload,
      factors,
    };
  },
});
